.button{
	padding: 10px 20px;
	background: $white;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: $bold;
	position: relative;
	z-index: 1;
	transition: .3s ease;
	cursor: pointer;
	display: inline-flex;
	text-align: center;
	&--center{
		margin-left: 50%;
		transform: translateX(-50%);
	}
	&--404{
    background: white;
    color: #AF0E2D;
    opacity: 0.9;
    &:hover {
      background: #F9105F;
      opacity: 0.7;
      color: white;
    }
	}
}