@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=latin-ext');

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

$base-font:		'Open sans', sans-serif;
$icon-font:		'Font Awesome 5 Pro';
$font-size:		18px;

$white:			#fff;
$black:			#000;
$base:			#21201f;
$alt:			#777777;
$gray:			#cccccc;
$rose: #f986b2;
$rose-text: #ce0f69;
$rose-nav: #b0718e;
$silver: #959595;
$text-color: #cccccc;
$border-color: #f2f2f2;

$light:          300;
$normal:         400;
$semibold:       600;
$bold:           700;

$nav-bar-height:	100px;